import { url } from "inspector";
import InvestSteps from "../components/InvestSteps";
import YourStory from "../components/YourStory";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";
import Header from "../common/Header";
import SlideBar from "../components/SlideBar";
import Footer from "../common/Footer";
import ReactSimplyCarousel from 'react-simply-carousel';
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { useAppStateAPI } from "../common/AppStateAPI";
import moment from "moment";
import ApplicationPopup from "../components/Popups/ApplicationPopup";

const Dashboard: React.FC = () => {
    const [toData, setToData] = useState<null | Date>();
    const [fromDate, setFromDate] = useState<null | Date>();

    const [ipoLists, setIpoList] = useState<APIData.IPOList[]>([]);
    const [search_text, setSearchText] = useState('');
    const [sortName, setSortName] = useState('');
    const navigate = useNavigate();
    const { setShowPreloader } = useAppStateAPI();
    const [applicationPopup, setApplicationPopup] = useState(false);


    const CustomInput = React.forwardRef((props: any, ref: any) => {
        return (
            <div className='datePickerStyle'>
                <label onClick={props.onClick} ref={ref}>
                    {props.value || props.placeholder}
                </label>
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 51.297 58.625">
                    <path id="Path_22586" data-name="Path 22586" d="M-1365.036,907.615a5.5,5.5,0,0,1-5.5,5.5h-40.3a5.5,5.5,0,0,1-5.5-5.5v-40.3a5.5,5.5,0,0,1,5.5-5.5h5.5V855.86a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v5.954h14.656V855.86a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v5.954h5.5a5.5,5.5,0,0,1,5.5,5.5Zm-5.5-34.808h-40.3v34.121a.689.689,0,0,0,.687.687h38.93a.689.689,0,0,0,.687-.687Zm-33.434,14.656a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58a1.378,1.378,0,0,1-1.374,1.374Zm4.58,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1399.386,898.455Zm10.992-10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1388.394,887.463Zm0,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1388.394,898.455Zm10.992-10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1377.4,887.463Zm0,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1377.4,898.455Z" transform="translate(1416.333 -854.486)" />
                </svg> */}
                <img src='../../assets/img/calender-icon.svg' onClick={props.onClick} ref={ref} />
            </div>
        );
    });

    useEffect(() => {
        ipodatalist();
    }, [])

    const ipodatalist = () =>{
        setShowPreloader(true);
        ServerAPI.getIpoList(search_text).then((response: any) => {
            if (response != undefined) {
                setIpoList(response['data']);
            }

        }).finally(() => {
            setShowPreloader(false);
        })
    }

    useEffect(()=>{
        ipodatalist();
    },[search_text])

    const NavigateDetail = (id:number) =>
        navigate({
            pathname: PageLinks.DASHBOARD_DETAIL + "/" + id,
        });

    return (
        <div className="main-index-wrapper">
            <Header />
            <SlideBar />
            <div className="body-overlay"></div>
            <main>
                <section className="plan-coverage-area aboutus_page pos-rel about-style-1 pt-110 pt-lg-50 pt-md-110 pb-md-30 pt-xs-10 pb-xs-30">
                    <div className="container-fluid">
                        <div className="col-lg-12 row">
                            <div className="col-lg-7">
                                <div className="story_box_left delaer-subscribe-area ">
                                    <div className="mt-4">
                                        <p className="dealer-bg-text fs-28 pl-3">Welcome Back,</p>
                                        <p className="dealer-bg-text fs-48 mt-3 pt-2 pl-3">{localStorage.getItem('dealer_name')}!</p>
                                        <img className="dealer-dashboard-img" src="../../assets/img/bg01.png" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 dealer-dashboard-content-right">
                                <p className="dashboard-title mt-3">Application Form</p>
                                <div className="dealer-dashboard-application-image cursor" onClick={(e:any)=>{
                                    e.preventDefault();
                                    setApplicationPopup(true);

                                }}>
                                    <img src="../assets/img/document.png" className="dealer-dashboard-application" /><br />
                                    <button className="dealer-dashboard-application-button"><i className="fa fa-download" aria-hidden="true">&nbsp;</i>Download Application Form</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="plan-coverage-area aboutus_page pos-rel about-style-1 pt-5 pb-50 pb-md-30 pt-xs-10 pb-xs-30">
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <div className="row align-items-left">
                                <p className="dashboard-title mt-2">Allocated Bonds / IPO</p>
                                <div className="mt-5">
                                    <div className="row">
                                        <div className="col-xl-7">
                                            <div className="d-flex justify-content-start align-items-center">
                                                <p className="text-bold p-2 m-0">Search :</p><form className="app-search card-title p-2 m-0">
                                                    <div className="position-relative">

                                                        <input type="text" className="select-style" onChange={(e) => setSearchText(e.target.value)} />&nbsp;
                                                        <i className="fa fa-search dashboard-search" aria-hidden="true"></i>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-xl-5 text-end">
                                            <div className="d-flex justify-content-end align-items-center">
                                                <form className="app-search card-title p-2 m-0 w-100">
                                                    <select className='dashboard-dropdown' name='user_status' >
                                                        <option>All</option>
                                                    </select>
                                                </form>
                                                <form className="app-search card-title p-2 w-100">
                                                    <div className="position-relative">
                                                        <DatePicker
                                                            selected={fromDate}
                                                            onChange={(date: Date) => setFromDate(date)}
                                                            placeholderText="From Date"
                                                            className='date-picker-style'
                                                            customInput={(<CustomInput />)}
                                                        />
                                                    </div>
                                                </form>
                                                <h4 className="label">TO</h4>
                                                <form className="app-search card-title p-2 w-100"><div className="position-relative">
                                                    <DatePicker
                                                        selected={toData}
                                                        onChange={(date: Date) => setToData(date)}
                                                        placeholderText="To Date"
                                                        className='date-picker-style'
                                                        customInput={(<CustomInput />)}
                                                    />
                                                </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <table className="w-100 dashboard-table">
                                        <thead>
                                            <tr>
                                                <th className="border-right">Sr.No</th>
                                                <th className="border-right">Bond/IPO Name</th>
                                                <th className="border-right">Start Date</th>
                                                <th className="border-right">End Date</th>
                                                <th className="border-right">Total No. Of  Single Page Form Generated</th>
                                                {/* <th className="border-right">Total No. Of  Single Page Form Allocated</th> */}
                                                <th className="border-right">Total No. Of  Two Page Form Generated</th>
                                                {/* <th className="border-right">Total No. Of  Two Page Form Allocated</th> */}
                                                <th className="border-right">Total No. Of  Abridged Form Generated</th>
                                                {/* <th className="border-right">Total No. Of  Abridged Form Allocated</th> */}
                                                <th>View</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {ipoLists != undefined && ipoLists.length > 0 &&
                                                ipoLists.map((ipo: APIData.IPOList,index:number) => {
                                                    return (
                                                        <tr>
                                                            <td className="border-right">{index+1}</td>
                                                            <td className="border-right">{ipo.bond_name}</td>
                                                            <td className="border-right">{moment(ipo.bond_opening_date).format("DD/MM/YYYY")}</td>
                                                            <td className="border-right">{moment(ipo.bond_closing_date).format("DD/MM/YYYY")}</td>
                                                            <td className="border-right">{ipo.single_allocated}</td>
                                                            <td className="border-right">{ipo.two_allocated}</td>
                                                            <td className="border-right">{ipo.abridged_allocated}</td>
                                                            <td className="cursor" onClick={(e: any) => {
                                                                e.preventDefault();
                                                                NavigateDetail(ipo.bond_id);
                                                            }}><i className="fas fa-eye table-icons px-2"></i></td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <ApplicationPopup showModal={applicationPopup} closeModal={()=>{setApplicationPopup(false)}} ipoList={()=>{ipodatalist()}} />
            <Footer />
        </div>
    )
}

export default Dashboard;
