export enum PageLinks {
    PROFILE = "/profile",
    LOGIN = "/login",
    CHANGEPASSWORD = "/change-password",
    LEARN_FROM_BOND = "/learn-from-bond",
    WHAT_ARE_ADVANTAGES = "/what-are-advantages",
    CHAT = "/chat",
    ABOUTUS = "/aboutus",
    NEWSANDINSIGHTS = "/news-and-insights",
    PRIVACY_POLICY = "/privacy-policy",
    TERMS_AND_CONDITIONS = "/terms-and-conditions",
    CONTACT_US = "/contact-us",
    DISCLAIMER = "/disclaimer",
    FAQ = "/faq",
    LOGINSUCCESS = "/Login-Success",
    DASHBOARD = '/dashboard',
    PASSWORD_CHANGE_SUCCESS = '/password-change-success',
    DASHBOARD_DETAIL = '/dashboard-detail',
    ALLOCATION_SUCESS = "/allocation-success",
    ACCOUNT_SETTINGS = "/user-settings",
    FORGOT_PASSWORD = "/password-reset",
    LOGIN_WITH_MOBILE = "/mobile-no-login",
    VERIFY_OTP_LOGIN = "/verify-otp-login"
}

export enum Endpoints {

    sendOTP = 'send-otp',
    OTPVerify = 'otp-verify',
    login = 'username-login',
    setPassword = 'set-password',
    tokenRefresh = 'token-refresh',
    loginCheck = 'login-check',
    IPOFormAllocationList = "ipo-form-allocations-list",
    IPOFormAllocations = 'ipo-form-allocations',
    Ipolist = 'ipo-list',
    IPOFormGenerate = "ipo-form-generate",
    AllocatingIpo = 'allocating/ipo-list'
}


export const APIResult = {
    SUCCESS: true,
    FAILURE: false
}


export const API = {
    /*Live*/
    // BaseUrl: "https://api.trustmony.com/dealer/api/v1/",
    BaseUrl: "https://trust-api.trustmony.com/dealer/api/v1/",
    EndPoint: Endpoints,
    Result: APIResult

}